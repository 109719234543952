import { app } from '../_config'

const header = new Headers()
header.append('Content-Type', 'application/json')

export const getUserDataFromServer = async (authToken, userId) => {
  const params = new URLSearchParams()
  params.append('auth_token', authToken)
  params.append('action', 'GET_USER_DATA')
  params.append('user_id', userId)

  const res = await fetch(app.BOT_API_URL + '?' + params.toString(), {
    method: 'GET',
    headers: header,
  })
    .then((res) => res.json())
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      console.log(e)
      return {}
    })
  return res
}

export const earnCreditsFromServer = async (authToken, userId) => {
  const params = new URLSearchParams()
  params.append('auth_token', authToken)
  params.append('action', 'EARN_CREDITS')
  params.append('user_id', userId)

  const res = await fetch(app.BOT_API_URL + '?' + params.toString(), {
    method: 'GET',
    headers: header,
  })
    .then((res) => res.json())
    .then((res) => {
      return res
    })
    .catch((e) => {
      console.log(e)
      return { error: true, text: 'Неизвестная ошибка' }
    })
  return res
}
