import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Context } from '../../context/main'
import Sidebar from '.'
import {
  actionChangeCurrentGuild,
  actionGetUserGuilds,
} from '../../store/guilds'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

export default function SidebarContainer() {
  const guilds = useSelector((state) => state.guildsReducer)
  const firebase = useSelector((state) => state.firebaseReducer)
  const dispatch = useDispatch()
  const context = useContext(Context)
  const authToken = context.authToken
  const location = useLocation()
  const [menuOpened, setMenuOpened] = useState(false)

  const loadUserGuilds = (allServers) => {
    dispatch(actionGetUserGuilds(authToken, allServers))
  }

  const guildListClick = (id) => {
    dispatch(actionChangeCurrentGuild(id))
  }

  const openMenuClick = () => setMenuOpened(true)
  const closeMenuClick = () => setMenuOpened(false)

  useEffect(() => {
    if (Object.keys(firebase.ALL_SERVERS).length)
      loadUserGuilds(firebase.ALL_SERVERS)
  }, [firebase.ALL_SERVERS])

  useEffect(() => {
    setMenuOpened(false)
  }, [location])

  useEffect(() => {
    setMenuOpened(false)
  }, [guilds.CUR_GUILD])

  return (
    <Sidebar
      guilds={guilds}
      guildListClick={guildListClick}
      openMenuClick={openMenuClick}
      closeMenuClick={closeMenuClick}
      menuOpened={menuOpened}
    />
  )
}
