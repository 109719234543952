import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserData from '.'
import { earnCreditsFromServer, getUserDataFromServer } from '../../utils/data'
import { Context } from '../../context/main'
import { actionAddAlert } from '../../store/alerts'

export default function UserDataContainer() {
  const context = useContext(Context)
  const user = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()

  const [userData, setUserData] = useState(null)

  const getUserData = async () => {
    const resData = await getUserDataFromServer(context.authToken, user.id)
    setUserData(resData)
  }

  const earnCredits = async () => {
    const resData = await earnCreditsFromServer(context.authToken, user.id)
    if (!!resData.success) {
      getUserData()
      dispatch(
        actionAddAlert({
          type: 'success',
          text: resData.text,
          id: new Date().getTime(),
        }),
      )
    } else {
      dispatch(
        actionAddAlert({
          type: 'danger',
          text: resData.text,
          id: new Date().getTime(),
        }),
      )
    }
  }

  const earnCreditsButtonClick = async () => {
    earnCredits()
  }

  useEffect(() => {
    if (!!user.id) getUserData()
  }, [user])

  // console.log(user)
  // console.log(userData)

  return (
    <UserData
      user={user}
      userData={userData}
      earnCreditsButtonClick={earnCreditsButtonClick}
    />
  )
}
