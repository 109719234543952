import React from 'react'
import Button from '../Button'
import Spinner from '../Spinner'
import PropTypes from 'prop-types'
import './style.scss'

export default function Header({ user = {}, logout = (e) => void 0 }) {
  return (
    <header>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6">
            <nav className="navbar navbar-dark pl-0 py-1">
              <div className="navbar-brand">
                {Object.keys(user).length ? (
                  <div className="d-flex align-items-center">
                    {user.avatar && (
                      <img
                        src={user.avatar}
                        className="d-inline-block align-top rounded-circle mr-3"
                        alt=""
                      />
                    )}
                    <span className="text-white">
                      {user.username}#{user.discriminator}
                    </span>
                  </div>
                ) : (
                  <Spinner />
                )}
              </div>
            </nav>
          </div>
          <div className="col-md-6 text-right">
            <Button text="Выход" classes="btn-danger" onClick={logout} />
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  user: PropTypes.object,
  guilds: PropTypes.object,
  logout: PropTypes.func,
  guildsOpened: PropTypes.bool,
  curGuildClick: PropTypes.func,
  guildListClick: PropTypes.func,
}
