import React from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../../context/main'
import { useContext } from 'react'
import './style.scss'
import burgerImage from '../../static/images/burger.svg'
import closeImage from '../../static/images/close.svg'

export default function Sidebar({
  guilds = [],
  guildListClick = (e) => void 0,
  openMenuClick = (e) => void 0,
  closeMenuClick = (e) => void 0,
  menuOpened = false,
}) {
  const context = useContext(Context)
  const links = context.links

  const cutGuildName = (name) => {
    let nameArr = name.split(' ')
    let newName = ''
    nameArr = nameArr.map((namePart) => {
      namePart = namePart[0].toUpperCase()
      return namePart
    })

    nameArr = nameArr.slice(0, 3)

    return nameArr.join('')
  }

  return (
    <>
      <button class="open-menu" onClick={openMenuClick}>
        <img src={burgerImage} alt="Открыть меню" />
      </button>
      <div class={menuOpened ? 'sidebar-wrapper opened' : 'sidebar-wrapper'}>
        <button class="close-menu" onClick={closeMenuClick}>
          <img src={closeImage} alt="Закрыть меню" />
        </button>
        {guilds.GUILDS &&
          (guilds.GUILDS.length ? (
            <div className="guilds-list-wrapper">
              <div className="guild-list">
                {guilds.GUILDS.map((guild) => {
                  return (
                    <div
                      key={guild.id}
                      className={
                        guild.id === guilds.CUR_GUILD.id
                          ? 'guild-item current'
                          : 'guild-item'
                      }
                      onClick={() => guildListClick(guild.id)}
                    >
                      {guild.icon ? (
                        <img
                          className="rounded-circle"
                          src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`}
                          alt={guild.name}
                        />
                      ) : (
                        <div class="no-guild-icon">
                          {cutGuildName(guild.name)}
                        </div>
                      )}
                      <div class="guild-name">{guild.name}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <div className={'guilds-list'}>Серверов не найдено</div>
          ))}

        <div className="card sidebar-card">
          <div className="card-body">
            <ul className="nav flex-column">
              {links.map((link) => {
                return (
                  <li className="nav-item" key={link.url}>
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to={link.url}
                    >
                      {link.name}
                    </NavLink>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        {/* <div className="mt-3">
      <iframe title="discord" src="https://discord.com/widget?id=844866169315262495&theme=dark" width="100%" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
    </div> */}
      </div>
    </>
  )
}
