import React, { useContext, useEffect } from 'react'
import { Context } from '../../context/main'
import { useDispatch, useSelector } from 'react-redux'
import { actionGetUser } from '../../store/user'

import Header from '.'
import { actionGetAllServersWhereBotIs } from '../../store/firebase'
import { actionLogoutUser } from '../../store/auth'

export default function HeaderContainer() {
  const context = useContext(Context)
  const authToken = context.authToken
  const user = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()

  const loadUser = () => {
    dispatch(actionGetUser(authToken))
  }

  const getAllServersWhereBotIs = () => {
    dispatch(actionGetAllServersWhereBotIs(context.authToken))
  }

  useEffect(() => {
    loadUser()
    getAllServersWhereBotIs()
  }, [])

  const logout = () => {
    dispatch(actionLogoutUser())
  }
  return <Header user={user} logout={logout} />
}
