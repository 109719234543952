import React from 'react'
import './style.scss'
export default function Footer() {
  return (
    <footer className="py-3">
      <div className="container">
        <div className="row">
          <div className="col-12 text-right">
            2021 - {new Date().getFullYear()}
            &nbsp;
            <a
              href="https://discord.gg/eMauW6ZmhJ"
              className="text-light"
              target="_blank"
              rel="noreferrer"
            >
              Hesh Junior
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
