import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  actionGetServerSettings,
  actionGetServerStat,
  actionSetServerStat,
} from '../store/firebase'
import { Context } from '../context/main'
import NoServers from '../components/NoServers'
import Spinner from '../components/Spinner'
import Stats from '../components/Stats'

export default function StatsContainer() {
  const guild = useSelector((state) => state.guildsReducer)
  const firebase = useSelector((state) => state.firebaseReducer)
  const dispatch = useDispatch()
  const context = useContext(Context)

  const getServerSettings = () => {
    dispatch(
      actionGetServerSettings(context.authToken, guild.CUR_GUILD.id, true),
    )
  }

  const getServerStat = () => {
    dispatch(actionGetServerStat(context.authToken, guild.CUR_GUILD.id))
  }

  const pad = (num, size) => {
    num = num.toString()
    while (num.length < size) num = '0' + num
    return num
  }

  useEffect(() => {
    if (!guild.CUR_GUILD.id) return

    if (!firebase.SETTINGS) return getServerSettings()

    if (!firebase.STAT) return getServerStat()

    if (guild.CUR_GUILD.id !== firebase.SETTINGS.serverId) {
      // dropServerStat();
      getServerSettings()
      return
    }
  }, [guild, firebase])

  const randomRgba = (opacity = 1) => {
    const r = Math.floor(Math.random() * 255) + 1
    const g = Math.floor(Math.random() * 255) + 1
    const b = Math.floor(Math.random() * 255) + 1
    return [`rgba(${r}, ${g}, ${b}, ${opacity})`, `rgba(${r}, ${g}, ${b}, 1)`]
  }

  const dataKey = 'Количество игроков'

  const findCurStat = (stats) => {
    // {
    //   name: '08.04.2022',
    //   'Количество игроков': 2400,
    // }

    const data = []

    let days = {}

    const now = new Date()

    let statsSorted = {}

    Object.keys(stats)
      .sort(function (a, b) {
        const dateA = new Date(a)
        const dateB = new Date(b)

        return dateA > dateB
      })
      .forEach(function (key) {
        statsSorted[key] = stats[key]
      })

    //filter for month
    for (let statDate in statsSorted) {
      const curStat = stats[statDate]
      const statDateObj = new Date(statDate)
      if (statDateObj.getMonth() !== now.getMonth()) {
        continue
      }

      curStat.forEach((statObj) => {
        const datePretty = `${pad(statDateObj.getDate(), 2)}.${pad(
          statDateObj.getMonth() + 1,
          2,
        )}.${statDateObj.getFullYear()}`
        if (!days[datePretty]) days[datePretty] = {}
        days[datePretty][statObj.name] = true
      })
    }

    for (let day in days) {
      const curDay = days[day]
      // data.labels.push(day)
      const obj = {
        name: day,
        [dataKey]: Object.keys(curDay).length,
      }
      data.push(obj)
    }

    return data
  }

  if (guild.GUILDS.length === 0) return <NoServers />

  return firebase.STAT ? (
    <Stats data={findCurStat(firebase.STAT)} dataKey={dataKey} />
  ) : (
    <Spinner card={true} />
  )
}
