import React from 'react'

export default function NoServers() {
  return (
    <div className="card">
      <div className="card-body">
        <h2>Подходящие сервера не найдены</h2>
        Видимо у вас нет серверов где присутствует данный бот
      </div>
    </div>
  )
}
