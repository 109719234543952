import React from 'react'
import Spinner from '../Spinner'
import './style.scss'

export default function UserData({
  user = {},
  userData = {},
  earnCreditsButtonClick,
}) {
  return (
    <div className="list-group user-data">
      {!!user.id ? (
        <div className="list-group-item header">
          {!!user.avatar && (
            <div className="image-wrapper">
              <img src={user.avatar} alt={user.username} />
            </div>
          )}
          <div class="username">
            {user.username}#{user.discriminator}
          </div>
          <div className="buttons">
            <button
              onClick={earnCreditsButtonClick}
              className="btn btn-primary"
            >
              Получить кредиты
            </button>
          </div>
        </div>
      ) : (
        <div className="list-group-item header">
          <Spinner />
        </div>
      )}

      <div className="list-group-item">
        <div className="data-items-list">
          <div className="item">
            <div className="item-inner">
              <div className="name">Кредиты</div>
              {userData === null ? (
                <Spinner />
              ) : (
                <div className="value">{userData.money || 0}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
